.office-incomes-container {
    .weekend {
       background-color: #f5d295; 
    }
}
.mt-2 {
    th {
        text-align: center;
        vertical-align: middle;
    }
}
.summary-value {
    min-width: 100px;
    display: inline-block;
    padding-left: 10px;
    margin-bottom: 0px;

}
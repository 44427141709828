.mt-2 {
    th {
        text-align: center;
        vertical-align: middle;
    }
}
.index {
    width: 50px;
}

.date {
    width: 120px;
}

.number {
    width: 150px;
}

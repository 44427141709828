a{
    text-decoration: none !important;
    color: black;
}
.pivot-table{
    text-align: end;
}
.align-right {
    text-align: right;
    min-width: 100px;
}
.pb-1 {
  margin: 5px;
}
.align-right-red {
    color: red;
    text-align: right;
}
.blue-align-right {
    color: rgb(23, 23, 231);
    text-align: right;
}
.bold-row {
    font-weight: bold;
}
.home-link {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.name-div {
    width:30%;
    display:inline-block;
}
.centered {
    width: 100%;
    display: flex;
    font-size: larger;
    justify-content: center;
    align-items: center;
}
.form-select {
    max-width: 250px !important;
}
.quarter {
    width: 25%;
}
.onefifth {
    width: 20%;
}

.column-width-60 {
    width: 60px;
    min-width: 60px !important;
}

.column-min-100 {
    min-width: 100px;
}

.column-min-120 {
    min-width: 120px;
}

.id-cell {
    width: 50px;
}

.react-datepicker__input-container input.form-control {
    max-width: 160px;
}

table.table > thead {
    vertical-align: middle;
}

table.table td {
    vertical-align: middle;
}

.rdp-range_start .rdp-day_button {
    background-color: var(--rdp-range_start-date-background-color);
    color: var(--rdp-range_start-color);
}

.rdp-range_start {
    background: var(--rdp-range_start-background);
}

.rdp-selected .rdp-day_button {
    border: var(--rdp-selected-border);
}

.weekPicker-wrapper .rdp-week .rdp-selected:not(.rdp-range_start):not(.rdp-range_end) {
    background-color: var(--rdp-range_middle-background-color);
}

.weekPicker-wrapper .rdp-week .rdp-selected:not(.rdp-range_start):not(.rdp-range_end) .rdp-day_button {
    border-color: transparent;
    border: unset;
    border-radius: unset;
    color: var(--rdp-range_middle-color);
}

.rdp-day_button,
.rdp-dropdown_root {
    font-size: 14px;
}

.rdp-caption_label {
    font-size: 16px;
}